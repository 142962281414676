'use client';

import MainNavigation from './MainNavigation';
import UserToolbar from './UserToolbar';

export default function Header() {
  return (
    <>
      <UserToolbar />
      <MainNavigation />
    </>
  );
}
