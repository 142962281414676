import { library } from '@fortawesome/fontawesome-svg-core';
import { IconLookup } from '@fortawesome/fontawesome-svg-core';
import {
  faApple,
  faGithub,
  faLinux,
  faYoutube,
  faWindows,
} from '@fortawesome/free-brands-svg-icons';
import {
  faCartShopping,
  faClockRotateLeft,
  faEye,
  faFileLines,
  faFilePdf,
  faFileVideo,
} from '@fortawesome/pro-light-svg-icons';

// Add icons to the library for global use
library.add(
  faApple,
  faFileLines,
  faFilePdf,
  faFileVideo,
  faGithub,
  faLinux,
  faYoutube,
  faWindows,
  faCartShopping,
  faClockRotateLeft,
  faEye
);

// Define a mapping from string identifiers to FontAwesome icon definitions
const iconMap = {
  apple: { prefix: 'fab', iconName: 'apple' },
  cartShopping: { prefix: 'fal', iconName: 'cart-shopping' },
  clockRotateLeft: { prefix: 'fal', iconName: 'clock-rotate-left' },
  eye: { prefix: 'fal', iconName: 'eye' },
  fileLines: { prefix: 'fal', iconName: 'file-lines' },
  filePdf: { prefix: 'fal', iconName: 'file-pdf' },
  fileVideo: { prefix: 'fal', iconName: 'file-video' },
  github: { prefix: 'fab', iconName: 'github' },
  linux: { prefix: 'fab', iconName: 'linux' },
  youtube: { prefix: 'fab', iconName: 'youtube' },
  windows: { prefix: 'fab', iconName: 'windows' },
} as const;

// Extract iconName values to create a union type
type IconMap = typeof iconMap;
export type IconName = IconMap[keyof IconMap]['iconName'];

// Utility function to convert kebab-case to camelCase
function kebabToCamel(str: string): string {
  return str.replace(/-./g, match => match[1].toUpperCase());
}

// Resolver function to get the icon definition from a string
export function resolveIcon(iconName: string): IconLookup {
  const camelCaseIconName = kebabToCamel(iconName) as IconName;
  const icon = iconMap[camelCaseIconName];
  if (!icon) {
    throw new Error(`Icon not found: ${iconName}`);
  }
  return icon;
}
