import React from 'react';

import { IconProp, IconLookup } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
} from '@mui/material';
import Link from 'next/link';

import { resolveIcon, IconName } from '@/ui/components/Button/Icons';
import Icon from '@/ui/components/Icon';

export interface ButtonProps extends MuiButtonProps {
  children?: React.ReactNode;
  label?: string;
  icon?: IconName;
  onClick?: () => void;
  href?: string;
  disabled?: boolean;
  loading?: boolean;
  type?: 'button' | 'submit' | 'reset';
  disableRipple?: boolean;
  tabIndex?: string | number | undefined;
  sx?: Record<string, unknown>;
}

const Button: React.FC<ButtonProps> = ({
  variant = 'contained',
  children,
  label,
  icon,
  color,
  href,
  disabled,
  type = 'button',
  loading,
  disableRipple = false,
  tabIndex = 0,
  sx = {},
  ...rest
}) => {
  let iconProp: IconProp | undefined = undefined;

  if (icon) {
    try {
      const iconLookup: IconLookup = resolveIcon(icon);
      iconProp = [iconLookup.prefix, iconLookup.iconName] as IconProp;
    } catch (error) {
      console.error(error.message);
    }
  }

  const buttonContent = (
    <MuiButton
      variant={variant}
      type={type}
      sx={{
        ...sx,
      }}
      {...rest}
      tabIndex={parseInt(`${tabIndex}`)}
      color={
        color as
          | 'info'
          | 'warning'
          | 'inherit'
          | 'primary'
          | 'secondary'
          | 'success'
          | 'error'
      }
      startIcon={
        loading ? (
          <Icon style="solid" name="spinner-third" spin />
        ) : iconProp ? (
          <FontAwesomeIcon icon={iconProp} />
        ) : undefined
      }
      disabled={disabled}
      disableRipple={disableRipple}
      disableTouchRipple={disableRipple}
      style={{
        ...(disabled && { borderColor: 'transparent' }),
      }}
    >
      {children || label}
    </MuiButton>
  );

  return href ? <Link href={href}>{buttonContent}</Link> : buttonContent;
};

export default Button;
