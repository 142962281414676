'use client';

// src/app/[locale]/not-found.tsx
import { Typography } from '@mui/material';
import { useTranslations } from 'next-intl';
import styled from 'styled-components';

import Button from '@/ui/components/Button';
import Footer from '@/ui/containers/layout/footer';
import Header from '@/ui/containers/layout/header';
import LayoutContainer from '@/ui/containers/layout/LayoutContainer';

const Wrapper = styled.div`
  display: flex;
  padding-top: 6.25rem;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
`;

const ButtonWrapper = styled.div`
  margin-top: 2rem;
`;

export default function NotFound(): JSX.Element {
  const t = useTranslations();

  return (
    <>
      <Header />
      <LayoutContainer>
        <Wrapper>
          <Typography variant="h1" component="h1">
            {t('not-found-title')}
          </Typography>

          <Typography
            variant="body1"
            component="p"
            sx={{
              marginTop: '2rem',
            }}
          >
            {t('not-found-description')}
          </Typography>

          <ButtonWrapper>
            <Button
              label={t('not-found-buttonLabel')}
              color="primary"
              size="xlarge"
              href="/"
            />
          </ButtonWrapper>
        </Wrapper>
      </LayoutContainer>
      <Footer />
    </>
  );
}
