'use client';

import MainFooter from '@prusa3d-platform/prusa-main-footer';
import Link from 'next/link';
import { useLocale } from 'next-intl';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { MAX_WIDTH } from '@/utils/breakpoints';

export default function Header() {
  const locale = useLocale();
  const { executeRecaptcha } = useGoogleReCaptcha();

  return (
    <>
      <MainFooter
        locale={locale}
        config={{
          maxWidth: `${MAX_WIDTH.breakpointValue + 48}px`,
          website: 'help',
          homeUrl: 'https://prusa3d.com/',
          parentHasRecaptchaProvider: true,
          externalExecuteRecaptcha: executeRecaptcha,
        }}
        LinkComponent={Link}
      />
    </>
  );
}
